import Grid from "@mui/material/Grid";
import React, {useState, useEffect, useCallback} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import axios from "axios";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Badge from '@mui/material/Badge';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { InstagramEmbed } from 'react-social-media-embed';

const CALENDAR_URL = process.env.REACT_APP_CALENDAR_URL

function DanceDay (props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? '💃' : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

function DanceDayInfo (props) {
    const {events} = props;

    if (events && events.length > 0 ) {
        return (
            <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
                {events.map((event) => (
                    <Card key={event.location}>
                        <CardContent>
                            <Typography variant={"h4"}>{event.location}</Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {event.start}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                to
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {event.end}
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {event.igLink ? <InstagramEmbed url={event.igLink} width='fluid'/> : null}
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </Grid>
        );
    }
    return null;
}

const Calendar = (props) => {
    const today = dayjs();
    // The Calendar with dance events. Basically the dance Days
    const [calendar, setCalendar] = useState(null);

    // The day to dispaly. Set the initial value of calendarDay to today
    const [calendarDay, setCalendarDay] = useState({day:today,events:null});
    // Keep track of loading state
    const [isLoading, setIsLoading] = React.useState(false);
    // The dates to highlight on the calendar
    const [highlightedDays, setHighlightedDays] = React.useState([]);

    const getEventsForDay = useCallback((dayToFilterFor,calendarEvents=calendar) => {
        // We are given a day object
        // Return an array with the events for the day
        if (calendarEvents){
            const eventsArr = calendarEvents.filter(calDay => calDay.day?.isSame(dayToFilterFor, 'day'));
            return {events: eventsArr};
        }
        // return empty array if we can't get events
        return {events:[]}
    },[calendar]);

    const parseCalendarEvent = (event) => {
        // Parse the start and end timestamps
        const startTs = dayjs(event.start)?.format('MMMM D, YYYY h:mm A')
        const endTs = dayjs(event.end)?.format('MMMM D, YYYY h:mm A')
        if (startTs){
            event.start = startTs
        }
        if (endTs){
            event.end = endTs
        }

        const day = {day: dayjs(event.date),...event};
        return day;
    }

    const parseCalendarEvents = useCallback((calendar) => {
        const calendarEvents = calendar.map(
            (event) => parseCalendarEvent(event)
        );
        // Set the highlighteDays
        const daysToHighlight = calendarEvents.filter(
            (calendarEvent) => (calendarEvent.day.month() === today.month())
        ).map(
            (calendarEvent) => calendarEvent.day.date()
        );

        setHighlightedDays(daysToHighlight);

        return calendarEvents;
    }, [today]);

    const loadCalendar = useCallback((f, monthNumber, day=today) => {
        axios.get(f, {params:{month:monthNumber}}).then((res) => {
            const calendarEvents = parseCalendarEvents(res.data.events);
            // bug: set calendar doesn't update the value until the next render
            setCalendar(calendarEvents);
            const todaysEvents = getEventsForDay(today,calendarEvents)
            setCalendarDay({day:today, ...todaysEvents})
        })
        .catch(error => {
            console.error(error)
        });
    },[getEventsForDay,parseCalendarEvents,today]);

    useEffect(() => {
        setIsLoading(true);
        try {
            // load the calendar for today's month 
            // Months are zero indexed, so add 1
            if (!calendar){
                // We're loading the calendar for the first time, so go ahead and set the curr day to today
                loadCalendar(CALENDAR_URL, today.month() + 1, today)
            }
            setIsLoading(false);
        } catch (error){
            setIsLoading(false);
            console.error('Unable to fetch the calendar',error);
        }
    }, [calendar, loadCalendar, calendarDay, today]); 

    const handleCalendarDayChange = (dayObj) => {
        // Set the new calendar day
        // TODO:, add the callbacks back in, get rid of the dayObj paramater to this function, flip the following 2 lines
        const events = getEventsForDay(dayObj);
        setCalendarDay({day:dayObj,...events});
    }

    const handleMonthChange = (event) => {
        // TODO: Refetch the calendar for the current month
        const month = event.month() + 1; // The month we're changing to. note month numbers are zero indexed, so add 1
        setIsLoading(true);
        try {
            loadCalendar(CALENDAR_URL, month);
            setIsLoading(false);
        } catch (error){
            setIsLoading(false);
            console.error('Unable to fetch the calendar',error);
        }
    };
    
    // TODO: Fix the text color. don't make it all secondary. Try rerenddering the grid so it's not 
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                value={calendarDay.day}
                loading={isLoading} 
                onChange={handleCalendarDayChange}
                maxDate={today.endOf("month")}
                disablePast={true}
                onMonthChange={handleMonthChange}
                renderLoading={() => <DayCalendarSkeleton />}
                slots = {{
                    day: DanceDay,
                }}
                slotProps={{
                    day: {
                        highlightedDays,
                    },
                }}
                />
                {calendarDay && calendarDay.events ? <DanceDayInfo events={calendarDay.events}/> : null}
        </LocalizationProvider>
    );
}

export default Calendar;