import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";

import Calendar from "./Calendar.jsx"

const Home = () => {
    return <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
            <Card>
                <CardContent>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Hello, I created this page as a way to better keep up with latin and other social dance events in Austin. Hopefully you'll find it useful as well!
                    </Typography>
                </CardContent>
            </Card>
            <Calendar/>
    </Grid>
};

export default Home;