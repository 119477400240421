import About from "../component/About.jsx"
import Contact from "../component/Contact.jsx"
import Calendar from "../component/Calendar.jsx"
import Home from "../component/Home.jsx"

const RoutesList = [
  {
    path: '/',
    sidebarName: 'Home',
    component: Home
  },
  {
    path: '/calendar',
    sidebarName: 'Calendar',
    component: Calendar
  },
  {
    path: '/about',
    sidebarName: 'About',
    component: About 
  },
  {
    path: '/contact',
    sidebarName: 'Contact',
    component: Contact 
  },
];

export default RoutesList;