import React from "react";
/*
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
*/

const Attendance = () => {
    return <h1>hello from Attendance</h1>;
    // TODO: Complete Impl
    /*
    return <Grid container>
        <Grid item>
            <Card>
                <CardContent>
                    <Typography variant={"h4"}>Contact Us</Typography>
                    <p>Hello this is where we'll mark attendance</p>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    */
}

export default Attendance;